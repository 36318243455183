import React, { useState, useEffect  } from 'react';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const apiUrl = process.env.REACT_APP_API_URL;


function Marcas() {

    const [selectedIds, setSelectedIds] = useState(new Set());
    const [productos, setProductos] = useState([]);
    const fetchData = async () => { 
        try {
            const response = await axios.get(`${apiUrl}/marcas/`);
            setProductos(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []); 
    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState({
        nombre: '', 
        descripcion:'',
        codigoCarga:''
    });
    const [editingProduct, setEditingProduct] = useState(null);

    const handleSelect = (id) => {
        setSelectedIds((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    };

    const handleEdit = (id) => {
        const product = productos.find(p => p._id === id);
        if (product) {
            setFormData({
                nombre: product.nombre,
                descripcion: product.descripcion,
                codigoCarga:product.codigoCarga
            });
            setEditingProduct(id);
            setIsOpen(true);
        }
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esto",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminarlo'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${apiUrl}/marcas/${id}`).then(response => {
                    if (response.status === 200) {
                        setProductos(productos.filter(p => p._id !== id));
                        fetchData();
                        Swal.fire(
                            'Eliminado!',
                            'La Marca ha sido eliminada.',
                            'success'
                        );
                    }
                }).catch(error => {
                    console.error('Error eliminando la zona:', error);
                });
            }
        });
    };

    const openModal = () => {
        setFormData({
            nombre: '',
            descripcion: '',
            codigoCarga:''
        });
        setEditingProduct(null);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

  

    const handleSubmit = async (e) => {
        e.preventDefault();
        try { 
         
            if (editingProduct) {
                const response = await axios.put(`${apiUrl}/marcas/${editingProduct}`, {nombre:formData.nombre,descripcion:formData.descripcion,codigoCarga:formData.codigoCarga});
                if (response.status === 200) {
                    fetchData();
                    console.log('Marca actualizada exitosamente');
                } else {
                    console.error('Error actualizando la zona');
                }
            } else {
                const response = await axios.post(`${apiUrl}/marcas`, {nombre:formData.nombre,descripcion:formData.descripcion,codigoCarga:formData.codigoCarga}, {
                    
                });
                if (response.status === 201) {
                    fetchData();
                    console.log('Marca creada exitosamente');
                } else {
                    console.error('Error creando la zona');
                }
            }
            closeModal();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="container mx-auto p-4">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">Marcas</h1>
                <button onClick={openModal} className="bg-blue-500 text-white px-3 py-2 rounded hover:bg-blue-600">
                    Agregar nuevo
                </button>
            </div>
            <div className="overflow-x-auto mt-2">
                <table className="min-w-full divide-y divide-gray-200 mb-4">
                    <thead className="bg-gray-200">
                        <tr>
                           
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre</th> 
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Acciones</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {productos.map((producto) => (
                            <tr key={producto._id}>
                               
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{producto.nombre}</td> 
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <div className="flex space-x-2">
                                        <PencilIcon
                                            className="h-5 w-5 text-blue-500 hover:text-blue-700 cursor-pointer"
                                            onClick={() => handleEdit(producto._id)}
                                        />
                                        <TrashIcon
                                            className="h-5 w-5 text-red-500 hover:text-red-700 cursor-pointer"
                                            onClick={() => handleDelete(producto._id)}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                        {editingProduct ? 'Editar Marca' : 'Agregar Nueva Marca'}
                                    </Dialog.Title>
                                    <form onSubmit={handleSubmit} className="mt-4">
                                        <div className="mb-4">
                                            <label className="block text-sm font-medium text-gray-700">Nombre</label>
                                            <input
                                                type="text"
                                                name="nombre"
                                                value={formData.nombre}
                                                onChange={handleChange}
                                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                required
                                            />
                                        </div> 
                                        <div className="mb-4">
                                            <label className="block text-sm font-medium text-gray-700">Descripción</label>
                                            <input
                                                type="text"
                                                name="descripcion"
                                                value={formData.descripcion}
                                                onChange={handleChange}
                                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                required
                                            />
                                        </div> 
                                        <div className="mb-4">
                                            <label className="block text-sm font-medium text-gray-700">Código  de Carga</label>
                                            <input
                                                type="text"
                                                name="codigoCarga"
                                                value={formData.codigoCarga}
                                                onChange={handleChange}
                                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                required
                                            />
                                        </div> 
                                        <div className="mt-4">
                                            <button
                                                type="submit"
                                                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                            >
                                                Guardar
                                            </button>
                                            <button
                                                type="button"
                                                className="ml-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 border border-transparent rounded-md hover:bg-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
                                                onClick={closeModal}
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
}

export default Marcas;

import React, { useState, useEffect } from 'react';
import axios from 'axios';  
import { useLocation } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

function Detalle() {
    const location = useLocation(); 
    const usuario = location.state.state;
    const [historial, setHistorial] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/points/${usuario._id}/historyb`);
                setHistorial(response.data.history);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [usuario._id]);

    const handleDeleteTransaction = async (transaction) => {
        
        const confirmDelete = window.confirm('¿Está seguro de que desea eliminar la transacción seleccionada?');
        if (!confirmDelete) {
            return;
        }

        try { 
            await axios.post(`${apiUrl}/points/delete-transaction`, {
                userId: usuario._id,
                transactionId: transaction._id
            });

            alert('Transacción eliminada con éxito');
            setHistorial(historial.filter(item => item._id !== transaction._id));

        } catch (error) {
            console.error('Error al eliminar transacción:', error);
            alert('Error al eliminar transacción');
        }
    };

    return (
        <div className="w-full c">
            <h2 className="text-red-600 text-xl font-bold mb-4 uppercase">DETALLE USUARIO / {usuario.nombres} {usuario.apellidos}</h2>
            <table className="w-full mb-4 border-collapse">
                <thead>
                    <tr className="bg-gray-200">
                        <th className="border px-4 py-2">TIPO</th>
                        <th className="border px-4 py-2">NOMBRE</th>
                        <th className="border px-4 py-2">FECHA</th>
                        <th className="border px-4 py-2">LITROS </th> 
                        <th className="border px-4 py-2">  </th> 
                    </tr>
                </thead>
                <tbody>
                    {historial.filter(item => item.type === 'ingreso').map((item, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : ''}>  
                            <td className="border px-4 py-2"> {item.type === 'ingreso' ? 'Recarga' : item.type === 'egreso' ? 'Compra' : item.type}</td>
                            <td className="border px-4 py-2">{item.marca ? item.marca.nombre+' - ' : ''}{item.product ? item.product.name : ''}{item.concurso ? item.concurso : ''}{item.mes ? ' - '+item.mes : ''}</td>
                            <td className="border px-4 py-2">{new Date(item.date).toLocaleDateString()}</td>
                            <td className="border px-4 py-2"> {item.type === 'ingreso' ? '' : item.type === 'egreso' ? '' : item.type}{item.amount}</td> 
                            <td className="border px-4 py-2">  
                                <button 
                                    onClick={() => handleDeleteTransaction(item)} 
                                    className="bg-red-500 text-white px-3 py-2 rounded hover:bg-red-600"
                                >
                                    Eliminar
                                </button> 
                            </td> 
                        </tr>
                    ))}
                    <tr className="bg-gray-100">
                        <td className="border px-4 py-2 font-bold">TOTAL LITROS CARGADOS</td>
                        <td className="border px-4 py-2"></td>
                        <td className="border px-4 py-2"></td>
                        <td className="border px-4 py-2">{usuario.account[0].totalIngresos}</td>
                        <td className="border px-4 py-2"></td> 
                    </tr>
                    <tr className="bg-gray-100">
                        <td className="border px-4 py-2 font-bold">TOTAL LITROS CANJEADOS</td>
                        <td className="border px-4 py-2"></td>
                        <td className="border px-4 py-2"></td>
                        <td className="border px-4 py-2">{usuario.account[0].totalEgresos}</td>
                        <td className="border px-4 py-2"></td> 
                    </tr>
                    <tr>
                        <td className="border px-4 py-2 font-bold text-red-600">TOTAL SALDO</td>
                        <td className="border px-4 py-2"></td>
                        <td className="border px-4 py-2"></td>
                        <td className="border px-4 py-2 text-red-600">{usuario.account[0].balance}</td>
                        <td className="border px-4 py-2"></td> 
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Detalle;
import React, { useState } from 'react';
import axios from 'axios';
import { Fragment } from 'react';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { Dialog, Transition } from '@headlessui/react';
import { Circles } from 'react-loader-spinner';

const apiUrl = process.env.REACT_APP_API_URL;

function CargarCanjes() {
    const [isOpen, setIsOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [resultado, setResultado] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData();
        formData.append('archivo', file);

        try {
            const response = await axios.post(`${apiUrl}/importar/giftcards`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setResultado(response.data.resultjson.sort((a, b) => a.fila - b.fila));
            openModal();
        } catch (error) {
            console.error(error);
            alert('Error al importar ingresos');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Litros de felicidad</h1>
            <h2 className="text-xl mb-2">Cargar Canjes</h2>
            <p className="mb-4">Para cargar los canjes debe subir un archivo Excel con los datos indicados</p>
            <table className="min-w-full divide-y divide-gray-200 mb-4">
                <thead className="bg-gray-200">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rut</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Usuario</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Producto</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Litros Gastados</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Zona</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fecha de canje</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">link</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">11111111-1</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">Nombre de usuario</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">GIFTCARD CENCOSUD</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">15000</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">Zona Centro</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">15-06-2024 23:16:30</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">https://cupon.giftcard.cl/cupon/gen/12623992/express_corp.pdf</td>
                    </tr>
                </tbody>
            </table>
            <p className="mb-4">
                Los productos se deben agregar previamente, y su nombre debe ser igual al del campo Producto del excel.
            </p>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="file" className="block text-sm font-medium text-gray-700">Archivo Excel</label>
                    <input type="file" id="file" accept=".xlsx" onChange={handleFileChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
                <div className="flex space-x-4">
                    <button type="submit" className="bg-blue-500 text-white px-3 py-2 rounded hover:bg-blue-600">Subir archivo</button>
                    <button type="button" onClick={() => window.history.back()} className="bg-gray-500 text-white px-3 py-2 rounded hover:bg-gray-600">Volver</button>
                </div>
            </form>
            {isLoading && (
                <div className="flex justify-center mt-4">
                    <Circles height="80" width="80" color="#4A90E2" ariaLabel="circles-loading" />
                </div>
            )}
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-screen-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                        Resultado de Importación
                                    </Dialog.Title>
                                    <div className="mb-4">
                                        <table className="min-w-full divide-y divide-gray-200 mb-4">
                                            <thead className="bg-gray-200">
                                                <tr>
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Numero de registro</th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mensaje</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {resultado.map((ress) => (
                                                    <tr key={ress.fila} className={ress.status}>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{ress.fila}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{ress.mensaje}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
}

export default CargarCanjes;
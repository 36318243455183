import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';

const apiUrl = process.env.REACT_APP_API_URL;

function VerCanjes() {
    const [fechaDesde, setFechaDesde] = useState('');
    const [fechaHasta, setFechaHasta] = useState('');
    const [marca, setMarca] = useState('');
    const [mesConcurso, setMesConcurso] = useState('');
    const [ventas, setVentas] = useState(null);
    const [selectedIds, setSelectedIds] = useState(new Set());

    const handleFilter = async () => {
        try {
            const response = await axios.get(`${apiUrl}/points/history`, {
                params: {
                    startDate: fechaDesde,
                    endDate: fechaHasta,
                    type: 'egreso'
                }
            });
            
            setVentas(response.data || []); 
        } catch (error) {
            console.error('Error fetching data:', error);
            setVentas([]); // En caso de error, establece un array vacío
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const fechaHasta = new Date();
            const fechaDesde = new Date();
            fechaDesde.setDate(fechaDesde.getDate() - 365);
        
            const formatFecha = (fecha) => {
                const year = fecha.getFullYear();
                const month = String(fecha.getMonth() + 1).padStart(2, '0'); // Los meses en JS van de 0 a 11
                const day = String(fecha.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            };
        
            const formattedFechaHasta = formatFecha(fechaHasta);
            const formattedFechaDesde = formatFecha(fechaDesde);
        
            try {
                const response = await axios.get(`${apiUrl}/points/history`, {
                    params: {
                        startDate: formattedFechaDesde,
                        endDate: formattedFechaHasta,
                        type: 'egreso'
                    }
                });
                
                setVentas(response.data || []); 
            } catch (error) {
                console.error('Error fetching data:', error);
                setVentas([]); // En caso de error, establece un array vacío
            }
        };
        fetchData();
    }, []);

    const handleSelect = (id) => {
        setSelectedIds((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    };

    const handleDeleteSelected = async () => {
        if (selectedIds.size === 0) {
            alert('No hay transacciones seleccionadas para eliminar.');
            return;
        }

        if (!window.confirm('¿Estás seguro de que deseas eliminar las transacciones seleccionadas?')) {
            return;
        }

        try {
            for (const transactionId of selectedIds) {
                const venta = ventas.find(v => v.id === transactionId);
                if (venta && venta.user && venta.user._id) {
                    await axios.post(`${apiUrl}/delete-transaction`, {
                        userId: venta.user._id,
                        transactionId: transactionId
                    });
                }
            }

            alert('Transacciones eliminadas correctamente.');
            handleFilter(); // Volver a cargar los datos después de eliminar
            setSelectedIds(new Set()); // Resetear las selecciones
        } catch (error) {
            console.error('Error al eliminar transacciones:', error);
            alert('Hubo un error al eliminar las transacciones.');
        }
    };

    const exportToExcel = () => {
        if (ventas.length === 0) {
            alert('No hay nada para exportar');
        } else {
            const flattenedData = ventas.map(venta => ({
                'RUT': venta.user.rut,
                'Usuario': `${venta.user.nombres} ${venta.user.apellidos}`,
                'Litros Gastados': venta.amount,
                'Producto': venta.product.name,
                'Fecha de actualización': venta.date
            }));
            const worksheet = XLSX.utils.json_to_sheet(flattenedData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Canjes");
            XLSX.writeFile(workbook, 'canjes.xlsx');
        }
    };

    const renderResults = (ventas) => {
        if (!ventas) {
            return <p></p>;
        } else if (ventas.length === 0) {
            return <p>No hay registros para mostrar</p>;
        } else {
            return (
                <table className="min-w-full divide-y divide-gray-200 mb-4">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Seleccionar</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">RUT</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Usuario</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Litros Gastados</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Producto</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fecha de actualización</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Entregada</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {ventas.sort((a, b) => new Date(b.date) - new Date(a.date)).map((venta) => (
                            <tr key={venta.id}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <input
                                        type="checkbox"
                                        checked={selectedIds.has(venta.id)}
                                        onChange={() => handleSelect(venta.id)}
                                    />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{venta.user?.rut || ''}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {venta.user ? `${venta.user.nombres} ${venta.user.apellidos}` : ''}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{venta.amount || ''}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{venta.product?.name || ''}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {venta.date ? new Date(venta.date).toLocaleDateString() : ''}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {venta.entregado ? `Entregada` : 'No Entregada'}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        }
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Historial de Canjes</h1>
            <div className="flex space-x-4 mb-4">
                <div>
                    <label htmlFor="fechaDesde" className="block text-sm font-medium text-gray-700">Desde</label>
                    <input type="date" id="fechaDesde" value={fechaDesde} onChange={(e) => setFechaDesde(e.target.value)} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
                <div>
                    <label htmlFor="fechaHasta" className="block text-sm font-medium text-gray-700">Hasta</label>
                    <input type="date" id="fechaHasta" value={fechaHasta} onChange={(e) => setFechaHasta(e.target.value)} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
                <button onClick={handleFilter} className="self-end bg-blue-500 text-white px-3 py-2 rounded hover:bg-blue-600">Filtrar</button>
            </div>
            <div className="flex space-x-4 mb-4">
                <button onClick={handleDeleteSelected} className="bg-red-500 text-white px-3 py-2 rounded hover:bg-red-600">Eliminar Seleccionados</button>
                <button onClick={exportToExcel} className="bg-green-500 text-white px-3 py-2 rounded hover:bg-green-600">Exportar a Excel</button>
            </div>
            <div className="overflow-x-auto mt-2">
                {renderResults(ventas)}
            </div>
        </div>
    );
}

export default VerCanjes;
import Zonas from 'components/zonas/Zonas'
import Layout from 'hocs/layout/Layout'
import React from 'react'

function Detail() {
    return (
        <Layout>
            <Zonas />
        </Layout>
    )
}

export default Detail
import React, { useState } from 'react';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

function CargarUsuarios() {
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('archivo', file);

        try {
            const response = await axios.post(`${apiUrl}/importar/usuarios`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            alert('Usuarios importados exitosamente');
        } catch (error) {
            console.error(error);
            alert('Error al importar usuarios');
        }
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Litros de felicidad</h1>
            <h2 className="text-xl mb-2">Cargar usuarios</h2>
            <p className="mb-4">Para cargar los usuarios debe subir un archivo Excel con los datos indicados</p>
            <table className="min-w-full divide-y divide-gray-200 mb-4">
                <thead className="bg-gray-200">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rut</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombres</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Apellidos</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cargo</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Perfil</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Zona</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">1234567-9</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">Juan Alberto</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">Perez Paredes</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">Vendedor</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">KAM</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">Zona 1</td>
                    </tr>
                </tbody>
            </table>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="file" className="block text-sm font-medium text-gray-700">Archivo Excel</label>
                    <input type="file" id="file" accept=".xlsx" onChange={handleFileChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
                <div className="flex space-x-4">
                    <button type="submit" className="bg-blue-500 text-white px-3 py-2 rounded hover:bg-blue-600">Subir archivo</button>
                    <button type="button" onClick={() => window.history.back()} className="bg-gray-500 text-white px-3 py-2 rounded hover:bg-gray-600">Volver</button>
                </div>
            </form>
        </div>
    );
}

export default CargarUsuarios;

import Layout from 'hocs/layout/Layout'
import React from 'react'

function Error404() {
    return (
        <Layout>
            Home
        </Layout>
    )
}

export default Error404
import CargarUsuarios from 'components/cargarusuarios/CargarUsuarios'
import Layout from 'hocs/layout/Layout'
import React from 'react'

function Detail() {
    return (
        <Layout>
            <CargarUsuarios />
        </Layout>
    )
}

export default Detail
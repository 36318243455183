
import React, { useState } from 'react'
import { LockClosedIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert2';
const apiUrl = process.env.REACT_APP_API_URL;

function Home() {

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })
    const [error, setError] = useState('');

    const { email, password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async e => {
        e.preventDefault();
        try {
            const res = await axios.post(`${apiUrl}/admin/login`, formData);
            localStorage.setItem('token', res.data.token); // Guarda el token en el localStorage
            navigate('/resumen');
        } catch (err) {
                if (err.response) {
                if (err.response.status === 401) {
                    setError('Invalid rut or password');
                    swal.fire('Error', 'Rut o contraseña incorrectos.', 'error');
                } else {
                    setError('An unexpected error occurred. Please try again later.');
                    swal.fire('Error', 'Ha ocurrido un error inesperado. Porfavor intente mas tarde.', 'error');
                }
            } else {
                setError(err.message);
                swal.fire('Error', err.message, 'error');
            } 
        }
    };

    return (
<div>
<div className="backgroundred">
                    <img
                        className="mx-auto h-32 w-auto"
                        src="https://litrosdefelicidad.cl/imagenes/logos/logo_litros.png"
                        alt="Litros de felicidad - Coca Cola Company"
                    />
                   
                </div>
        <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
           
            <div className="w-full max-w-md space-y-8 ">   
            <div>
                 
                    <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                       Ingresa a tu cuenta
                    </h2>
                </div>
                <form onSubmit={e => { onSubmit(e) }} className="mt-8 space-y-6" action="#" method="POST">
                    <input type="hidden" name="remember" defaultValue="true" />
                    <div className="-space-y-px rounded-md shadow-sm">
                        <div>
                            <label htmlFor="email-address" className="sr-only">
                                Correo
                            </label>
                            <input
                                id="email-address"
                                name="email"
                                value={email}
                                onChange={e => onChange(e)}
                                type="email"
                                required
                                className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                placeholder="Email"
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="sr-only">
                                Password
                            </label>
                            <input
                                id="password"
                                name="password"
                                value={password}
                                onChange={e => onChange(e)}
                                type="password"
                                required
                                className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                placeholder="Password"
                            />
                        </div>
                    </div>

                    <div className="flex items-center justify-between">

                        <div className="text-sm">
                            <a href="#" className="font-medium text-red-600 hover:text-red-500">
                              
                            </a>
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="group relative flex w-full justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <LockClosedIcon className="h-5 w-5 text-red-500 group-hover:text-red-400" aria-hidden="true" />
                            </span>
                            Entrar
                        </button>
                    </div>
                </form>
            </div>
        </div></div>
    )
}

export default Home
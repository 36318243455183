import Slides from 'components/slides/Slides'
import Layout from 'hocs/layout/Layout'
import React from 'react'

function Detail() {
    return (
        <Layout>
            <Slides />
        </Layout>
    )
}

export default Detail
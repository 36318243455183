import React, { useState, useEffect  } from 'react';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const apiUrl = process.env.REACT_APP_API_URL;


function Productos() {
    const [selectedIds, setSelectedIds] = useState(new Set());
    const [productos, setProductos] = useState([]);
    const fetchData = async () => {
        try {
            const response = await axios.get(`${apiUrl}/products/`);
            setProductos(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []); 
    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        type: 'normal',
        options: [{ value: 0, label: '' }],
        quantityRange: { min: 0, max: 0 },
        value: 0,
        image: null,
        imagePreview: ''
    });
    const [editingProduct, setEditingProduct] = useState(null);

    const handleSelect = (id) => {
        setSelectedIds((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    };

    const handleEdit = (id) => {
        const product = productos.find(p => p._id === id);
        if (product) {
            if(!product.quantityRange){
                 product.quantityRange= { min: 0, max: 0 }  ; 
            }
            setFormData({
                name: product.name,
                description: product.description,
                type: product.type,
                options: product.options,  // Ajustar según los datos reales
                quantityRange: product.quantityRange,  
                value: product.value,  // Ajustar según los datos reales
                image: null,
                imagePreview: '',
                imageAnt: product.image,
            });
            setEditingProduct(id);
            setIsOpen(true);
        }
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esto",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminarlo'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${apiUrl}/products/${id}`).then(response => {
                    if (response.status === 200) {
                        setProductos(productos.filter(p => p._id !== id));
                        fetchData();
                        Swal.fire(
                            'Eliminado!',
                            'El producto ha sido eliminado.',
                            'success'
                        );
                    }
                }).catch(error => {
                    console.error('Error eliminando el producto:', error);
                });
            }
        });
    };

    const openModal = () => {
        setFormData({
            name: '',
            description: '',
            type: 'normal',
            options: [{ value: 0, label: '' }],
            quantityRange: { min: 0, max: 0 },
            value: 0,
            image: null,
            imagePreview: '',
            imageAnt: null,
        });
        setEditingProduct(null);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            image: file,
            imagePreview: URL.createObjectURL(file)
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('name', formData.name);
            formDataToSend.append('description', formData.description);
            formDataToSend.append('type', formData.type);
            if (formData.type === 'selectable') {
                formDataToSend.append('options', JSON.stringify(formData.options));
            }
            if (formData.type === 'open') {
                formDataToSend.append('quantityRange', JSON.stringify(formData.quantityRange));
            }
            if (formData.type === 'normal') {
                formDataToSend.append('value', formData.value);
            }
            if (formData.image) {
                formDataToSend.append('image', formData.image);
            }

            if (editingProduct) {
                const response = await axios.put(`${apiUrl}/products/${editingProduct}`, formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if (response.status === 200) {
                    fetchData();
                    console.log('Producto actualizado exitosamente');
                } else {
                    console.error('Error actualizando el producto');
                }
            } else {
                const response = await axios.post(`${apiUrl}/products/add`, formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if (response.status === 201) {
                    fetchData();
                    console.log('Producto creado exitosamente');
                } else {
                    console.error('Error creando el producto');
                }
            }
            closeModal();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="container mx-auto p-4">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">Productos</h1>
                <button onClick={openModal} className="bg-blue-500 text-white px-3 py-2 rounded hover:bg-blue-600">
                    Agregar nuevo
                </button>
            </div>
            <div className="overflow-x-auto mt-2">
                <table className="min-w-full divide-y divide-gray-200 mb-4">
                    <thead className="bg-gray-200">
                        <tr>
                            
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre</th>  
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Acciones</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {productos.map((producto) => (
                            <tr key={producto._id}>
                             
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{producto.name}</td> 
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <div className="flex space-x-2">
                                        <PencilIcon
                                            className="h-5 w-5 text-blue-500 hover:text-blue-700 cursor-pointer"
                                            onClick={() => handleEdit(producto._id)}
                                        />
                                        <TrashIcon
                                            className="h-5 w-5 text-red-500 hover:text-red-700 cursor-pointer"
                                            onClick={() => handleDelete(producto._id)}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                        {editingProduct ? 'Editar Producto' : 'Agregar Nuevo Producto'}
                                    </Dialog.Title>
                                    <form onSubmit={handleSubmit} className="mt-4">
                                        <div className="mb-4">
                                            <label className="block text-sm font-medium text-gray-700">Nombre</label>
                                            <input
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                required
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-sm font-medium text-gray-700">Descripción</label>
                                            <input
                                                type="text"
                                                name="description"
                                                value={formData.description}
                                                onChange={handleChange}
                                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-sm font-medium text-gray-700">Tipo</label>
                                            <select
                                                name="type"
                                                value={formData.type}
                                                onChange={handleChange}
                                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                            >
                                                <option value="selectable">Seleccionable</option>
                                                <option value="open">Abierto</option>
                                                <option value="normal">Normal</option>
                                            </select>
                                        </div>
                                        {formData.type === 'selectable' && (
                                            <div className="mb-4">
                                                <label className="block text-sm font-medium text-gray-700">Opciones</label>
                                                {formData.options.map((option, index) => (
                                                    <div key={index} className="flex space-x-2 mb-2">
                                                        <input
                                                            type="number"
                                                            name={`optionValue${index}`}
                                                            value={option.value}
                                                            onChange={(e) => {
                                                                const newOptions = formData.options.slice();
                                                                newOptions[index].value = e.target.value;
                                                                setFormData({ ...formData, options: newOptions });
                                                            }}
                                                            placeholder="Value"
                                                            className="p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                            required
                                                        />
                                                        <input
                                                            type="text"
                                                            name={`optionLabel${index}`}
                                                            value={option.label}
                                                            onChange={(e) => {
                                                                const newOptions = formData.options.slice();
                                                                newOptions[index].label = e.target.value;
                                                                setFormData({ ...formData, options: newOptions });
                                                            }}
                                                            placeholder="Label"
                                                            className="p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                            required
                                                        />
                                                    </div>
                                                ))}
                                                <button
                                                    type="button"
                                                    onClick={() => setFormData({ ...formData, options: [...formData.options, { value: 0, label: '' }] })}
                                                    className="text-blue-500 hover:text-blue-700"
                                                >
                                                    Agregar Opcion
                                                </button>
                                            </div>
                                        )}
                                        {formData.type === 'open' && (
                                            <div className="mb-4">
                                                <label className="block text-sm font-medium text-gray-700">Rango Cantidad</label>
                                                <div className="flex space-x-2">
                                                    <input
                                                        type="number"
                                                        name="quantityRangeMin"
                                                        value={formData.quantityRange.min}
                                                        onChange={(e) => setFormData({ ...formData, quantityRange: { ...formData.quantityRange, min: e.target.value } })}
                                                        placeholder="Min"
                                                        className="p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                        required
                                                    />
                                                    <input
                                                        type="number"
                                                        name="quantityRangeMax"
                                                        value={formData.quantityRange.max}
                                                        onChange={(e) => setFormData({ ...formData, quantityRange: { ...formData.quantityRange, max: e.target.value } })}
                                                        placeholder="Max"
                                                        className="p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {formData.type === 'normal' && (
                                            <div className="mb-4">
                                                <label className="block text-sm font-medium text-gray-700">Valor</label>
                                                <input
                                                    type="number"
                                                    name="value"
                                                    value={formData.value}
                                                    onChange={handleChange}
                                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                    required
                                                />
                                            </div>
                                        )}
                                        <div className="mb-4">
                                        {formData.imageAnt && (
                                            <div className="imagenant">
                                                <p>Imagen Actual</p>
                                            <img src={'https://litrosdefelicidad.cl/imagenes/'+formData.imageAnt} alt="Selected" className="mt-2 h-32 w-32 object-cover" />
                                            </div>
                                              
                                            )}
                                            <label className="block text-sm font-medium text-gray-700">Seleccionar Imagen</label>
                                            <input
                                                type="file"
                                                name="image"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                            />
                                            {formData.imagePreview && (
                                                <img src={formData.imagePreview} alt="Selected" className="mt-2 h-32 w-32 object-cover" />
                                            )}
                                           
                                        </div>
                                        <div className="mt-4">
                                            <button
                                                type="submit"
                                                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                            >
                                                Guardar
                                            </button>
                                            <button
                                                type="button"
                                                className="ml-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 border border-transparent rounded-md hover:bg-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
                                                onClick={closeModal}
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
}

export default Productos;

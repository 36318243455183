import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'


import { AnimatePresence } from 'framer-motion'
import Error404 from 'containers/errors/Error404'
import Home from 'containers/pages/Home'
import DescargarUsuarios from 'containers/pages/DescargarUsuarios'
import VerCanjes from 'containers/pages/VerCanjes'
import VerLitrosSubidos from 'containers/pages/VerLitrosSubidos'
import CargarLitros from 'containers/pages/CargarLitros'
import CargarCanjes from 'containers/pages/CargarCanjes'
import CargarUsuarios from 'containers/pages/CargarUsuarios'
import Resumen from 'containers/pages/Resumen'
import Detalle from 'containers/pages/Detalle'
import ProtectedRoute from 'containers/auth/ProtectedRoutes'
import Productos from 'containers/pages/Productos'
import Promociones from 'containers/pages/Promociones'
import Slides from 'containers/pages/Slides'
import Zonas from 'containers/pages/Zonas'
import Perfiles from 'containers/pages/Perfiles'
import Marcas from 'containers/pages/Marcas' 
function AnimatedRoutes() {

    const location = useLocation()

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                {/* Error Display */}
                <Route path="*" element={<Error404 />} />

                {/* Home Display */}
                <Route path="/" element={<Home />} />

                {/* Other Routes Display */}
            

                 <Route path="/ver_canjes" element={<ProtectedRoute><VerCanjes /></ProtectedRoute>} />
                <Route path="/ver_litros_subidos" element={<ProtectedRoute><VerLitrosSubidos /></ProtectedRoute>} />
                <Route path="/cargar_litros" element={<ProtectedRoute><CargarLitros /></ProtectedRoute>} />
                <Route path="/resumen" element={<ProtectedRoute><Resumen /></ProtectedRoute>} />
                <Route path="/detalles/:id"   element={<ProtectedRoute><Detalle /></ProtectedRoute>} />
                <Route path="/cargar_usuarios" element={<ProtectedRoute><CargarUsuarios /></ProtectedRoute>} />
                <Route path="/cargar_canjes" element={<ProtectedRoute><CargarCanjes /></ProtectedRoute>} />
                <Route path="/productos" element={<ProtectedRoute><Productos /></ProtectedRoute>} />
                <Route path="/promociones" element={<ProtectedRoute><Promociones /></ProtectedRoute>} />
                <Route path="/slides" element={<ProtectedRoute><Slides /></ProtectedRoute>} />
                <Route path="/zonas" element={<ProtectedRoute><Zonas /></ProtectedRoute>} />
                <Route path="/perfiles" element={<ProtectedRoute><Perfiles /></ProtectedRoute>} />
                <Route path="/marcas" element={<ProtectedRoute><Marcas /></ProtectedRoute>} />
            </Routes>
        </AnimatePresence>
    )
}
export default AnimatedRoutes
import React, { useState } from 'react';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

function CargarLitros() {

    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('archivo', file);

        try {
            const response = await axios.post(`${apiUrl}/importar/litros`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            alert('Ingresos importados exitosamente');
        } catch (error) {
            console.error(error);
            alert('Error al importar ingresos');
        }
    };


    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Litros de felicidad</h1>
            <h2 className="text-xl mb-2">Cargar litros</h2>
            <p className="mb-4">Para cargar los litros debe subir un archivo Excel con los datos indicados</p>
            <table className="min-w-full divide-y divide-gray-200 mb-4">
                <thead className="bg-gray-200">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rut</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Litros</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Marca</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Concurso</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mes de concurso</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">1234567-9</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">2000</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">STILLS</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">Descripción concurso</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">Febrero</td>
                    </tr>
                </tbody>
            </table>
            <p className="mb-4">
                Las marcas se deben agregar previamente en esta sección señalando el campo que irá en el Excel, las marcas ya disponibles son:
            </p>
            <ul className="list-disc pl-5 mb-4">
                <li>Marca: Litros Monster / Campo en el Excel: MONSTER</li>
                <li>Marca: Litros Santa Rita / Campo en el Excel: SANTA RITA</li>
                <li>Marca: Litros Guallarauco / Campo en el Excel: GUALLARAUCO</li>
                <li>Marca: Litros ABI / Campo en el Excel: ABI</li>
                <li>Marca: Litros Capel / Campo en el Excel: CAPEL</li>
                <li>Marca: Litros Diageo / Campo en el Excel: DIAGEO</li>
                <li>Marca: Litros STILLS / Campo en el Excel: STILLS</li>
                <li>Marca: Litros SSD / Campo en el Excel: SSD</li>
            </ul>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="file" className="block text-sm font-medium text-gray-700">Archivo Excel</label>
                    <input type="file" id="file" accept=".xlsx" onChange={handleFileChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
                <div className="flex space-x-4">
                    <button type="submit" className="bg-blue-500 text-white px-3 py-2 rounded hover:bg-blue-600">Subir archivo</button>
                    <button type="button" onClick={() => window.history.back()} className="bg-gray-500 text-white px-3 py-2 rounded hover:bg-gray-600">Volver</button>
                </div>
            </form>
        </div>
    );
}

export default CargarLitros;

import React from 'react'

import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  UsersIcon,
  ArrowDownTrayIcon,
  ShoppingBagIcon,
  CreditCardIcon,
  UserGroupIcon,
  TagIcon,
  MapIcon
} from '@heroicons/react/24/outline'
import { NavLink, useLocation } from 'react-router-dom'
const apiUrl = process.env.REACT_APP_API_URL;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Sidebar() {

  const location = useLocation()

  const navigation = [
    { name: 'Resumen', href: '/resumen', icon: HomeIcon, current: location.pathname === '/resumen' ? true : false },
    { name: 'Cargar Usuarios', href: '/cargar_usuarios', icon: UsersIcon, current: location.pathname === '/cargar_usuarios' ? true : false },
    { name: 'Cargar Litros', href: '/cargar_litros', icon: FolderIcon, current: location.pathname === '/cargar_litros' ? true : false },
    { name: 'Enviar Giftcards', href: '/cargar_canjes', icon: CreditCardIcon, current: location.pathname === '/cargar_canjes' ? true : false },
    { name: 'Ver Litros Subidos', href: '/ver_litros_subidos', icon: CalendarIcon, current: location.pathname === '/ver_litros_subidos' ? true : false },
    { name: 'Historial de Canjes', href: '/ver_canjes', icon: InboxIcon, current: location.pathname === '/ver_canjes' ? true : false },
     { name: 'Productos y Giftcards', href: '/productos', icon: ShoppingBagIcon, current: location.pathname === '/productos' ? true : false },
    { name: 'Perfiles', href: '/perfiles', icon: UserGroupIcon, current: location.pathname === '/perfiles' ? true : false },
    { name: 'Marcas', href: '/marcas', icon: TagIcon, current: location.pathname === '/marcas' ? true : false },
    { name: 'Zonas', href: '/zonas', icon: MapIcon, current: location.pathname === '/zonas' ? true : false },  
    { name: 'Promociones', href: '/promociones', icon: ChartBarIcon, current: location.pathname === '/promociones' ? true : false },
  ]

  return (
    <div>
      {navigation.map((item) => (
        <NavLink
          key={item.name}
          to={item.href}
          className={classNames(
            item.current ? 'bg-red-600 text-white ' : 'text-white hover:bg-red-900 hover:text-white',
            'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
          )}
        >
          <item.icon
            className={classNames(
              item.current ? 'text-white ' : 'text-white  group-hover:text-white',
              'mr-3 flex-shrink-0 h-6 w-6'
            )}
            aria-hidden="true"
          />
          {item.name}
        </NavLink>
      ))}
    </div>
  )
}

export default Sidebar
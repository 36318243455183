import VerLitrosSubidos from 'components/verlitrossubidos/VerLitrosSubidos'
import Layout from 'hocs/layout/Layout'
import React from 'react'

function Dashboard() {
    return (
        <Layout>
            <VerLitrosSubidos />
        </Layout>
    )
}

export default Dashboard
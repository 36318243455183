import Resumen from 'components/resumen/Resumen'
import Layout from 'hocs/layout/Layout'
import React from 'react'

function Resumenn() {
    return (
        <Layout>
            <Resumen />
        </Layout>
    )
}

export default Resumenn